import React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import CardActions from "@mui/material/CardActions"
import { CardActionArea } from "@mui/material"
import Button from "@mui/material/Button"

export default function PostCard({
  title,
  description,
  image,
  slug,
  image_alt,
}) {
  return (
    <Card
      sx={{ maxWidth: 350, maxHeight: 700, backgroundColor: "transparent" }}
      raised="true"
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="200"
          image={image}
          sx={{ m: "5px" }}
          title={image_alt}
        />

        <CardContent sx={{ pt: 0, pb: 0 }}>
          <Typography gutterBottom variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" variant="contained" color="info" href={slug}>
            Read full post
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  )
}
