import React from "react"
import { Link } from "gatsby"
import Typography from "@mui/material/Typography"

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext

  return (
    <div style={{ marginTop: 20, marginBottom: 50 }}>
      {previousPagePath && (
        <span>
          <Link to={previousPagePath}>
            <Typography
              textAlign="center"
              sx={{
                mr: 2,
                ml: 2,
                mb: 1,
                display: { xs: "flex", md: "flex" },
                fontFamily: "Helvetica",
                fontWeight: 700,
                fontSize: 20,
                letterSpacing: ".3rem",
                textDecoration: "none",
              }}
            >
              Previous posts
            </Typography>
          </Link>
        </span>
      )}
      {nextPagePath && (
        <span>
          <Link to={nextPagePath}>
            <Typography
              textAlign="center"
              sx={{
                mr: 2,
                ml: 2,
                display: { xs: "flex", md: "flex" },
                fontFamily: "Helvetica",
                fontWeight: 700,
                fontSize: 20,
                letterSpacing: ".3rem",

                textDecoration: "none",
              }}
            >
              Next posts
            </Typography>
          </Link>
        </span>
      )}
    </div>
  )
}

export default Pager
