import React from "react"
import { graphql } from "gatsby"
import Layout from "./layout"
import SEO from "./seo"
import Grid from "@material-ui/core/Grid"
import PostCard from "./postCard"
import Pager from "./pager"

const Blog = ({ data, pageContext }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout pageName="Home" pageLink="/">
      <SEO
        titleTag={`Blog page number ${pageContext.pageNumber} - TheTechOasis`}
        description={`Next six blog posts on page number ${pageContext.pageNumber} of TheTechOasis blog and newsletter, the blog that prepares you for the future digitally-led economy.`}
      />
      <div style={{ height: "100%" }}>
        <div
          style={{
            margin: "30px auto",
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "400px",
            fontSize: "1.5rem",
          }}
        >
          <h1
            style={{
              marginBottom: "10%",
              color: "black",
              fontFamily: "Arial",
              fontSize: "47px",
            }}
          >
            Blog<span style={{ color: "#FF0063" }}> Posts</span>.
          </h1>
        </div>
        <div style={{ margin: "60px" }}>
          <Grid
            container
            spacing={4}
            sx={{ m: 3 }}
            justifyContent="center"
            alignItems="center"
            direction="row"
          >
            {posts.map(post => (
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <PostCard
                  title={post.node.frontmatter.title}
                  description={post.node.frontmatter.desc}
                  image={post.node.frontmatter.image_link}
                  slug={post.node.frontmatter.slug}
                  image_alt={post.node.frontmatter.image_alt}
                />
              </Grid>
            ))}
          </Grid>
          <Pager pageContext={pageContext} />
          <div style={{ height: "10px" }}></div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            image_link
            desc
            date
            image_alt
          }
        }
      }
    }
  }
`

export default Blog
